import { useState, useEffect, useContext } from 'react'

// Components
import Button from '../../Elements/Button'
import RadioButton from '../../Elements/RadioButton'
import Tooltip from '../../Elements/Tooltip'
import { UserContext } from '../../Hooks/UserContext'

// API
import { profileService, userService } from '../../../api'

function EditProfileForm(props) {
	const [errors, setErrors] = useState({})
	const [profileImage, setProfileImage] = useState(null)
	const [hasNewImage, setHasNewImage] = useState(false)

	const [onEdit, setOnEdit] = useState(false)
	const [isUpdated, setIsUpdated] = useState({ user: false, profile: false })
	const [onLoading, setOnLoading] = useState(false)

	const { currentUser } = useContext(UserContext)

	const showEagIdField = currentUser.role === 'SUPER_ADMIN'

	const [value, setValue] = useState({
		first_name: '',
		middle_name: '',
		last_name: '',
		alias: '',
		phone_number: '',
		email: '',
		occupation: '',
		facebook_link: '',
		instagram_link: '',
		youtube_link: '',
		linkedin_link: '',
		eag_id: '',
	})

	if (!profileImage && props.profilePic) {
		setProfileImage(props.profilePic)
	}

	useEffect(() => { }, [profileImage])

	useEffect(() => {
		if (isUpdated.user && isUpdated.profile) {
			props.onClose(true)
			setOnLoading(false)
			window.location.reload()
		}

	}, [isUpdated.user, isUpdated.profile])

  useEffect(() => {

		setTimeout(() => {
			setValue({
				first_name: props.user?.first_name,
				middle_name: props.user?.middle_name,
				last_name: props.user?.last_name,
				alias: props.user?.alias,
				phone_number: props.user?.phone_number,
				email: props.user?.email,
				occupation: props.user?.profile?.occupation,
				facebook_link: props.user?.profile?.facebook_link,
				instagram_link: props.user?.profile?.instagram_link,
				youtube_link: props.user?.profile?.youtube_link,
				linkedin_link: props.user?.profile?.linkedin_link,
				eag_id: props.user?.eag_id
			})
		}, 500)
	}, [props.placeholder])

	const hdlChange = (e, field) => {
		setValue({ ...value, [field]: e.target.value })
	}

	function handleSubmit(e) {
		e.preventDefault()

		setOnLoading(true)

		var form_element = document.getElementById('edit-profile-form')
		var form_data = new FormData(form_element)
		var user_body = {}
		var profile_body = {}

		var toDelete = []
		for (const [key, value] of form_data.entries()) {

			if (
				[
					'private',
					'first_name',
					'middle_name',
					'last_name',
					'alias',
					'email',
					'phone_number',
					'eag_id'
				].includes(key)
			) {
				user_body[key] = value
				toDelete.push(key)
			} else if (props.user[key] && !value) {
				form_data[key] = null
			} else if (
				[
					'occupation',
					'facebook_link',
					'instagram_link',
					'youtube_link',
					'linkedin_link',
				].includes(key)
			) {
				profile_body[key] = value
			}
		}

		for (const key of toDelete) {
			form_data.delete(key)
		}



		Object.entries(user_body).forEach((key) => {
			if (props.user[key[0]] === key[1]) {
				delete user_body[key[0]]
			}
			if (key[1] === '' && !props.user[key[0]]) {
				delete user_body[key[0]]
			}
			if (key[1] === '' && props.user[key[0]]) {
				user_body[key[0]] = ' '
			}
		})

		Object.entries(profile_body).forEach((key) => {
			form_data.set(key[0], key[1])
			if (key[1] === '' && props.user?.profile[key[0]]) {
				profile_body[key[0]] = ' '
				form_data.set(key[0], ' ')
			}
		})




		profileService
		.updatePic(form_data, props.user.cognito_id)
		.then((response) => {
			setHasNewImage(false)
			setIsUpdated((prev) => { return {...prev, profile:true}})
		})
		.catch((error) => {
			console.log('Error updating profile', error)
			if (Array.isArray(error)) {
				error = { non_field_errors: error[0] }
			}
			setErrors({ ...error })
		})

		userService
			.update(user_body, props.user.cognito_id)
			.then((response) => {
				setIsUpdated((prev) => { return { ...prev, user: true } })
				props.onNewVerification('phone_number' in user_body, 'email' in user_body)
			})

			.catch((error) => {
				if (Array.isArray(error)) {
					error = { non_field_errors: error[0] }
				}
				setErrors({ ...error })
			})

	}

	function handleFileLoader() {
		var element = document.getElementById('file-loader')
		element.click()
	}

	function handleChangeImage({ target }) {
		if (target.files && target.files[0]) {
			setProfileImage(URL.createObjectURL(target.files[0]))
			setHasNewImage(true)
		}
	}

	const inputFields = [
		{ name: 'first_name', placeholder: 'First name' },
		{ name: 'middle_name', placeholder: 'Middle name' },
		{ name: 'last_name', placeholder: 'Last name' },
		{ name: 'alias', placeholder: 'Alias' },
	].map((item) => (
		<fieldset
			style={{
				padding: '1rem 0',
				border: 'none',
			}}
		>
			<label className='input-field__label'>{item.placeholder}</label>
			<input
				name={item.name}
				onChange={(e) => hdlChange(e, item.name)}
				value={value[item.name]}
				onFocus={() => setOnEdit(true)}
				className={'input-field__input'}
			/>
		</fieldset>
	))

	const private_text = 
	<div className="" style={{padding: '0px 10px'}}>
		<p className="text line-margin-0 is-ligth">A private profile displays only limited information. Any member of</p>
		<p className="text line-margin-0 is-ligth">your organization can see your public information, including your </p>
		<p className="text line-margin-0 is-ligth">name, profile picture, alias, email, social network links, and role.</p>
		<p className="text line-margin-0 is-ligth">Admins and super admins also can see your phone and occupation.</p>
		<p className="text line-margin-0 is-ligth">Anyone outside your organization only can see your name, profile</p>
		<p className="text line-margin-0 is-ligth">picture, and alias.</p>
	</div>

	var radioButtons
	if (props.user.private) {
		radioButtons =
			<>
				<RadioButton label="Public" class="flexer-3" name="private" required={true} value="false" checked={true} />
				<Tooltip content={private_text} direction="bottom-x" class="flexer-3">
					<RadioButton label="Private" class="flexer-3" name="private" required={true} value="true" checked={true} />
				</Tooltip>
			</>
	}
	else {
		radioButtons =
			<>
			<Tooltip content={private_text} direction="bottom-x" class="flexer-3">
				<RadioButton label="Private" class="flexer-3" name="private" required={true} value="true" checked={true} />
			</Tooltip>
				<RadioButton label="Public" class="flexer-3" name="private" required={true} value="false" checked={true} />
			</>
	}

	return (
		<>
			<form id='edit-profile-form' onSubmit={handleSubmit}>
				<h1 className='title is-dark mb-30'>Edit Profile</h1>
				<div className='flexer flexer-vcenter'>
					<input
						id='file-loader'
						name='profile_pic'
						type='file'
						className='file-input'
						onChange={handleChangeImage}
					/>
					{profileImage ? (<img
					 className='EditProfileImage'
					 src={profileImage}
					 />) : (<div className='EditProfileImage'>{props.initials}</div>)}
					<Button
						type='button'
						text='Upload photo'
						class='mx-15 blue-btn subheading is-white'
						onClick={handleFileLoader}
					/>
				</div>
				<div className='flexer flexer-vcenter mt-30'>
					<p className='subheading is-dark flexer-3'>Type of profile: </p>
					{radioButtons}
				</div>

				{showEagIdField && (
					<fieldset
						style={{
							padding: '1rem 0',
							border: 'none',
						}}
					>
						<label className='input-field__label'>EAG ID</label>
						<input
							name='eag_id'
							onChange={(e) => hdlChange(e, 'eag_id')}
							value={value.eag_id}
							onFocus={() => setOnEdit(true)}
							className={'input-field__input'}
						/>
					</fieldset>
				)}

				{inputFields}

				<Tooltip content='example@example.com' direction='bottom' class='full-width'>
					<fieldset
						style={{
							padding: '1rem 0',
							border: 'none',
						}}
					>
						<label className='input-field__label'>{'Email'}</label>

						<input
							name='email'
							onChange={(e) => hdlChange(e, 'email')}
							value={value.email}
							onFocus={() => setOnEdit(true)}
							className={'input-field__input'}
							type='email'
						/>
						<p className='error-message'>{errors.username || errors.email}</p>
					</fieldset>
				</Tooltip>

				<Tooltip
					content='Phone number should start with a + and the country code. For example +19041234567'
					direction='bottom'
					class='full-width'
				>
					<fieldset
						style={{
							padding: '1rem 0',
							border: 'none',
						}}
					>
						<label className='input-field__label'>{'Phone'}</label>

						<input
							name='phone_number'
							onChange={(e) => hdlChange(e, 'phone_number')}
							value={value.phone_number}
							onFocus={() => setOnEdit(true)}
							className={'input-field__input'}
							type='tel'
						/>
						<p className='error-message'>{errors.phone_number}</p>
					</fieldset>
				</Tooltip>

				<fieldset
					style={{
						padding: '1rem 0',
						border: 'none',
					}}
				>
					<label className='input-field__label'>{'Occupation'}</label>

					<input
						name='occupation'
						onChange={(e) => hdlChange(e, 'occupation')}
						value={value.occupation}
						onFocus={() => setOnEdit(true)}
						className={'input-field__input'}
					/>
				</fieldset>

				<Tooltip content='http://www.facebook.com/example' direction='bottom' class='full-width'>
					<fieldset
						style={{
							padding: '1rem 0',
							border: 'none',
						}}
					>
						<label className='input-field__label'>{'Facebook link'}</label>

						<input
							name='facebook_link'
							onChange={(e) => hdlChange(e, 'facebook_link')}
							value={value.facebook_link}
							onFocus={() => setOnEdit(true)}
							className={'input-field__input'}
						/>
					</fieldset>
				</Tooltip>

				<Tooltip content='http://www.instagram.com/example' direction='bottom' class='full-width'>
					<fieldset
						style={{
							padding: '1rem 0',
							border: 'none',
						}}
					>
						<label className='input-field__label'>{'Instagram link'}</label>

						<input
							name='instagram_link'
							onChange={(e) => hdlChange(e, 'instagram_link')}
							value={value.instagram_link}
							onFocus={() => setOnEdit(true)}
							className={'input-field__input'}
						/>
					</fieldset>
				</Tooltip>

				<Tooltip content='http://www.youtube.com/example' direction='bottom' class='full-width'>
					<fieldset
						style={{
							padding: '1rem 0',
							border: 'none',
						}}
					>
						<label className='input-field__label'>{'Youtube link'}</label>

						<input
							name='youtube_link'
							onChange={(e) => hdlChange(e, 'youtube_link')}
							value={value.youtube_link}
							onFocus={() => setOnEdit(true)}
							className={'input-field__input'}
						/>
					</fieldset>
				</Tooltip>

				<Tooltip content='http://www.linkedin.com/example' direction='bottom' class='full-width'>
					<fieldset
						style={{
							padding: '1rem 0',
							border: 'none',
						}}
					>
						<label className='input-field__label'>{'LinkedIn link'}</label>

						<input
							name='linkedin_link'
							onChange={(e) => hdlChange(e, 'linkedin_link')}
							value={value.linkedin_link}
							onFocus={() => setOnEdit(true)}
							className={'input-field__input'}
						/>
					</fieldset>
				</Tooltip>
				<div className='right center-mobile'>
					<Button
						text='Cancel'
						class='mt-30 mx-15 white-btn subheading is-dark'
						onClick={props.onClose}
					/>
					<Button text='Save changes' class={`${onLoading ? 'white-btn' : 'blue-btn'} mt-30 subheading is-white`} type='submit' disable={onLoading} />
				</div>
			</form>
		</>
	)
}

export default EditProfileForm
